<template>
  <v-container fluid class="pa-0 d-flex">
    <div class="ma-auto" :class="classProp" v-if="editable">
      <v-badge avatar bordered overlap bottom offset-x="20" offset-y="20">
        <template v-slot:badge>
          <v-avatar @click="onClick">
            <v-icon>mdi-pencil</v-icon>
          </v-avatar>
        </template>

        <v-avatar size="100" @click="onClick" class="rounded-xl">
          <v-img :src="avatar" style="border:4px solid #4802d3;" :alt="alt" />
        </v-avatar>
      </v-badge>
      <v-file-input
        ref="filePicker"
        v-show="false"
        @change="onChange"
        accept="image/*"
      />
    </div>
    <div class="ma-auto" :class="classProp" v-else>
      <v-avatar size="100">
        <v-img :src="avatar" style="border:4px solid #4802d3;" :alt="alt" />
      </v-avatar>
      <b class="mr-4">{{ name }}</b>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      // v-model instance
      required: true,
    },
    alt: {
      type: String,
      default: "آواتار",
    },
    name: {
      type: String,
      default: "",
    },
    classProp: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick() {
      this.editable && this.$refs.filePicker.$refs.input.click();
    },
    onChange(img) {
      this.$emit("input", img);
    },
  },
  computed: {
    avatar: {
      get() {
        if (this.value == null) return require('@/assets/img/man.jpg');
        else if (typeof this.value == "object") {
          return URL.createObjectURL(this.value);
        }
        return this.value;
      },
      set(img) {
        this.$emit("input", img);
      },
    },
  },
};
</script>

<style></style>
